<script setup lang="ts">
const props = withDefaults(defineProps<{
  columns?: number
  multiple?: boolean
  open?: string | string[]
}>(),
{
  columns: 4,
  multiple: true,
  open: [] as any,
})

const emit = defineEmits<{
  (event: 'change', args: string[]): void
}>()

const runtimeConfig = useRuntimeConfig()
const companyName = runtimeConfig.public.company.name

const isOpen = ref<string[]>([])
const items = ref<string[]>([])

function toggle(payload: string) {
  if (!props.multiple)
    isOpen.value = [payload]
  else if (isOpen.value.includes(payload))
    isOpen.value = isOpen.value.filter(item => item !== payload)
  else
    isOpen.value = [...isOpen.value, payload]

  emit('change', isOpen.value)
}

const isColumnOpen = (title: string) => isOpen.value.includes(title)
const addFooterItem = (title: string) => items.value.push(title)

const socials = useSocials()

provide('footerContextKey', { isColumnOpen, toggle, addFooterItem, items })
</script>

<template>
  <footer class="bg-white pt-2" aria-labelledby="footer-heading">
    <div v-if="$slots.prefooter" class="pt2 xl:mt0 md:pt8">
      <slot name="prefooter" />
    </div>
    <div class="mx-auto max-w-[88rem] w-full px4 py2 lg:px8 lg:py6 lg:pb-0 md:py4 sm:px-6">
      <div class="pb-2 xl:grid xl:grid-cols-4 xl:gap-8">
        <div
          class="flex flex-1 flex-col md:grid md:gap-8"
          :style="{
            'grid-template-columns': `repeat(${columns}, minmax(0, 1fr))`,
            'grid-column': `span ${columns} / span ${columns}`,
          }"
        >
          <slot />
        </div>
      </div>
    </div>
    <div v-if="$slots.subfooter" class="xl:mt0">
      <slot name="subfooter" />
    </div>
    <div class="mx-auto max-w-[88rem] w-full border-t n-border-200">
      <div class="flex flex-col items-center border-t border-gray-200 py-2 md:flex-row md:justify-between md:py-4">
        <div class="flex md:order-2 space-x-6">
          <div v-for="social in socials" :key="social.label">
            <NLink :href="social.href" class="text-context" n="slate4 hover:slate5">
              <span class="sr-only" v-text="social.label" />
              <NIcon :icon="social.icon" n="2xl black" class="text-black" />
            </NLink>
          </div>
        </div>

        <p class="text-center text-base text-gray-400 md:order-1">
          &copy; {{ new Date().getFullYear() }} {{ companyName }}, {{ $t('general.all_rights_reserved.label') }}.
        </p>
      </div>
    </div>
  </footer>
</template>
