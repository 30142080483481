<script setup lang="ts">
const { locale, t } = useI18n()
// make reviews object with dummy data
const reviews = [
  {
    id: 1,
    customerName: t('footer_reviews.review1.name'),
    review: t('footer_reviews.review1.review'),
    imgUrl: '/reviews/img000000267.jpg',
  },
  {
    id: 2,
    customerName: t('footer_reviews.review2.name'),
    review: t('footer_reviews.review2.review'),
    imgUrl: '/reviews/img000000445.jpg',
  },
  {
    id: 3,
    customerName: t('footer_reviews.review3.name'),
    review: t('footer_reviews.review3.review'),
    imgUrl: '/reviews/img000000163.jpg',
  },
]
</script>

<template>
  <div class="bg-white">
    <h3 class="k-text-heading mb-2 text-base font-semibold">
      {{
        $t('trustable.last_store_reviews')
      }}
    </h3>
    <div class="mb:mt-16 mt-2 lg:col-span-7 lg:col-start-6 lg:mt-0">
      <div class="flow-root">
        <div class="pb-2 -my-4 divide-y divide-gray-200">
          <FooterReviewItem
            v-for="review in reviews"
            :key="review.id"
            :customer-name="review.customerName"
            :review="review.review"
            :img-url="review.imgUrl"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
