<script setup lang="ts">
const props = defineProps<{
  show?: boolean
  title?: string
  closable?: boolean
  slideFrom?: 'left' | 'right'
}>()

const emit = defineEmits<{
  (event: 'close'): void
}>()

const slideFrom = computed(() => props.slideFrom || 'right')

const classNames = computed(() => {
  if (slideFrom.value === 'right') {
    return {
      dialogPanelContainer: 'right-0 pl10',
      dialogPanel: 'rounded-l-xl',
      dialogPanelEnter: 'translate-x-full',
      dialogPanelEnterTo: 'translate-x-0',
      dialogPanelLeave: 'translate-x-0',
      dialogPanelLeaveTo: 'translate-x-full',
    }
  }

  return {
    dialogPanelContainer: 'left-0 pr10',
    dialogPanel: 'rounded-r-xl',
    dialogPanelEnter: '-translate-x-full',
    dialogPanelEnterTo: 'translate-x-0',
    dialogPanelLeave: 'translate-x-0',
    dialogPanelLeaveTo: '-translate-x-full',
  }
})
</script>

<template>
  <TransitionRoot id="drawer" :show="show" as="template">
    <Dialog
      as="div"
      class="fixed inset-0 z50 overflow-hidden"
      @close="$emit('close')"
    >
      <div class="fixed inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="absolute inset-0 bg-slate-900/25 backdrop-blur-sm" />
        </TransitionChild>

        <div class="fixed inset-y-0 max-w-full flex" :class="classNames.dialogPanelContainer">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-300"
            :enter-from="classNames.dialogPanelEnter"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-300"
            leave-from="translate-x-0"
            :leave-to="classNames.dialogPanelLeaveTo"
          >
            <div class="max-w-md w-screen @container">
              <DialogPanel
                id="drawer-panel"
                class="h-full flex flex-col overflow-y-auto bg-white shadow-xl ring-1 ring-black/10"
                :class="classNames.dialogPanel"
              >
                <div v-if="!$slots.header" class="flex items-start justify-between border-b border-slate-200 bg-gray-100 px-4 py-3 sm:px-6">
                  <DialogTitle id="drawer-title" class="font-medium text-context" n="slate9 lg">
                    {{ title }}
                  </DialogTitle>

                  <div v-if="closable" class="ml-3 h-7 flex items-center">
                    <NButton
                      n="lg borderless"
                      icon="heroicons:x-mark"
                      class="p-0.25em children:m-0 sm:-m-2"
                      @click="$emit('close')"
                    />
                  </div>
                </div>
                <div v-else>
                  <slot name="header" />
                </div>

                <div class="flex-1 overflow-y-auto px-2 sm:px-3">
                  <slot />
                </div>

                <div v-if="$slots.footer">
                  <slot name="footer" />
                </div>
              </DialogPanel>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
