export function getWeekdays(locale: string): string[] {
  const baseDate = new Date(Date.UTC(2022, 0, 10)) // Start from a Monday
  const weekdays = []

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(baseDate.getTime() + i * 24 * 60 * 60 * 1000)
    weekdays.push(new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(currentDate))
  }

  return weekdays
}

export function getHours(locale: string): string[] {
  const hours = []

  for (let i = 0; i < 24; i++) {
    const date = new Date(0, 0, 0, i, 0, 0)
    hours.push(new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: 'numeric' }).format(date))
  }

  return hours
}
