<script setup lang="ts">

</script>

<template>
  <div class="mx-auto max-w-[88rem] w-full px4 py-2 lg:(px8 py2) md:py12 md:py4 sm:(px-6) md:pb6">
    <div class="relative max-h-80 w-full flex flex-col items-center md:flex-row">
      <div class="w-full flex flex-col flex-wrap py-3 md:(w-1/2 py-0)">
        <h5 class="text-sm font-bold">
          {{
            $t('trustable.payment_methods')
          }}
        </h5>
        <div class="flex flex-wrap items-center flex-gap2">
          <img src="/payment_methods/visa.svg" alt="Visa" class="w-12 p-1">
          <img src="/payment_methods/card.svg" alt="Card" class="w-12 p-1">
          <img src="/payment_methods/maestro.svg" alt="Maestro" class="w-12 p-1">
          <img src="/payment_methods/diners-club.svg" alt="Diners Club" class="w-12 p-1">
          <img src="/payment_methods/american-express.svg" alt="American Express" class="w-12 p-1">
          <img src="/payment_methods/discover.svg" alt="Discover" class="w-12 p-1">
          <img src="/payment_methods/jcb.svg" alt="JCB" class="w-12 p-1">
          <img src="/payment_methods/apple-pay.svg" alt="Apple Pay" class="w-12 p-1">
          <img src="/payment_methods/google-pay.svg" alt="Google Pay" class="aspect-1 w-12 p-1">
        </div>
      </div>
      <div class="w-full flex flex-col flex-wrap py-3 md:(w-1/2 py-0)">
        <h5 class="text-sm font-bold">
          {{
            $t('trustable.security_certificates')
          }}
        </h5>
        <div class="flex flex-wrap items-center flex-gap2">
          <img src="/certificates/visa-secure.png" alt="Visa Secure" class="w-13 p-1">
          <img src="/certificates/mcbc-id-check.png" alt="MCBC ID Check" class="w-13 p-1">
          <img src="/certificates/jcb-j-secure.png" alt="JCB J/Secure" class="w-13 p-1">
          <img src="/certificates/american-express-safekey.svg" alt="American Express SafeKey" class="w-13 p-1">

          <img src="/certificates/norton_secure.png" alt="Norton Secure" class="w-13 p-1">
          <img src="/certificates/pci-dss.png" alt="PCI DSS" class="w-13 p-1">
          <img src="/certificates/SSL-secure.jpg" alt="SSL Secure" class="w-13 p-1">
          <img src="/certificates/trusted_site.png" alt="Trusted Site" class="w-13 p-1">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
