<script setup lang="ts">
const { title } = defineProps<{
  title?: string
}>()

const footerContext = inject<{ addFooterItem: (title: string) => void; toggle: (payload: string) => void }>('footerContextKey', {
  addFooterItem: () => {},
  toggle: () => {},
})

if (title)
  footerContext.addFooterItem(title)
</script>

<template>
  <div>
    <ul role="list" class="mt-4 space-y-4">
      <slot />
    </ul>
  </div>
</template>
